<template>
  <div class="active_meeting page row">
    <div class="col-lg-9 col-md-12 col-12" style="height: fit-content">
      <div class="active_meeting_title">
        <h2 class="cardTitle">{{ meeting.title }}</h2>
        <div class="cardAction">
          <div class="meetingTime">
            <span>
              <i class="far fa-clock"></i>
              الوقت المتبقي
            </span>
            <vue-countdown
              v-if="
                Remainin_time != 0 &&
                  meeting.status == 'inprogress' &&
                  Metting_stutes != 'complete'
              "
              :time="Remainin_time"
              v-slot="{ hours, minutes }"
              class="direction_initial"
            >
              <strong>{{ hours }}</strong> h
              <div class="mx-1">:</div>
              <strong> {{ minutes }}</strong> m
            </vue-countdown>

            <span
              v-else-if="
                meeting.status == 'pending' && Metting_stutes != 'complete'
              "
              >لم يبدأ بعد</span
            >
            <span v-else>تم الإنتهاء</span>
          </div>
          <button
            v-if="
              meeting.status == 'inprogress' &&
                (user_date.email == meeting.email ||
                  user_roles.includes('admin')) &&
                !IsGust
            "
            type="button"
            @click="user_left_meeting()"
            class="btn_danger"
          >
            إنهاء الإجتماع
          </button>
          <button
            v-else-if="
              meeting.status == 'inprogress' &&
                (user_date.email != meeting.email ||
                  !user_roles.includes('admin'))
            "
            type="button"
            @click="user_left_meeting()"
            class="btn_danger"
          >
            خروج
          </button>
        </div>
      </div>
      <div class="Zoom_frame">
        <div class="col-md-9 col-12 ctive_zoom">
          <!--   nickname="user"
            :meetingId="meeting_id"
            :passWord="meeting.pwd" -->
          <!-- https://us04web.zoom.us/j/78425306656?pwd=dDNKamJEUnBQRnNMblN6UFBzamFyUT09 -->
          <!-- https://us04web.zoom.us/j/79299097201?pwd=WEgzMVZKcXRDWkNSMTZzQUN5QnQvUT09 -->
          <!-- && Metting_stutes != 'finshed' -->
          <!-- <ZoomFrame
          v-if="meeting.status == 'inprogress'"
          style="height: 100%;"
           nickname="user"
            :meetingId="meeting_id"
            :passWord="meeting.pwd"
          />  -->
          <iframe
            v-if="
              meeting.status == 'inprogress' &&
                Metting_stutes != 'complete' &&
                meeting.admin_start_meeting != 0
            "
            width="100%"
            height="100%"
            allow="microphone; camera"
            :src="`/#/zoom/${meeting_id}/${meeting.pwd}`"
            frameborder="0"
          ></iframe>
          <div
            class="pending_meeting"
            v-else-if="
              meeting.status == 'inprogress' &&
                Metting_stutes != 'complete' &&
                meeting.admin_start_meeting == 0
            "
          >
            <h6>برجاء الإنتظار حتى يقوم منسق الاجتماع بالبدأ</h6>
          </div>
          <div
            v-else-if="
              meeting.status == 'pending' && Metting_stutes != 'complete'
            "
            class="pending_meeting"
          >
            <h6>الأجتماع سوف يبدأ بعد</h6>

            <div class="counter text-center">
              <vue-countdown
                :time="Remainin_time_to_start"
                v-slot="{ days, hours, minutes, seconds }"
                class="d-flex justify-content-center"
              >
                <div class="d-block time">
                  <strong> {{ days }}</strong> <br />
                  يوم
                </div>

                <div class="d-block time">
                  <strong> {{ hours }}</strong> <br />
                  ساعة
                </div>
                <div class="d-block time">
                  <strong> {{ minutes }}</strong> <br />
                  دقيقة
                </div>
                <div class="d-block time">
                  <strong> {{ seconds }}</strong> <br />
                  ثانية
                </div>
              </vue-countdown>
            </div>
          </div>
          <div class="finsh_meeting" v-else>تم الإنتهاء من هذا الإجتماع</div>
        </div>
        <div class="col-md-3 col-12 p-0 users_bg">
          <div class="leftBlocks" style="overflow: auto">
            <div class="leftBlock">
              <div class="blockHead">
                <i class="fas fa-users"></i>
                الأشخاص
              </div>
              <div class="listUsers">
                <draggable
                  :list="meeting.users"
                  :group="{ name: 'people', pull: 'clone', put: false }"
                >
                  <div
                    class="listItem"
                    v-for="(item, index) in meeting_users"
                    :key="index"
                  >
                    <div class="userAvatar">
                      <img :src="item.avatar" />
                    </div>
                    <div class="userName">{{ item.name }}</div>
                    <div class="userStatus">
                      <div :class="item.status"></div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="leftBlocks" style="overflow: auto">
            <div
              class="leftBlock groups_list"
              v-for="(group, index) in meeting.groups"
              :key="index"
            >
              <div
                class=""
                style="color: #1c5f95; font-size: 18px; font-weight: bold"
              >
                <!-- <i class="fas fa-bullhorn"></i> -->
                {{ group.name }}
              </div>
              <div class="listUsers">
                <div
                  class="listItem"
                  v-for="(user, index) in group.users"
                  :key="index"
                >
                  <div class="userAvatar">
                    <img :src="user.avatar" />
                  </div>
                  <div class="userName">{{ user.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!IsGust">
        <div class="note_section" v-if="!user_roles.includes('viewer')">
          <div class="col-md-10 col-12 note_section_right py-5">
            <h5 class="note_title">ملخص الإجتماع</h5>
            <div class="text_editor">
              <wysiwyg placeholder="" v-model="myHTML" />
            </div>
          </div>
          <div class="col-md-2 col-12 py-5">
            <button
              @click="CreateMeetingNote()"
              class="btn_Gray btn_main btn_note mb-2"
            >
              <span>حفظ كمسودة</span>
            </button>
            <button
              @click="CreateMeetingNote('send')"
              class="btn_Green btn_main btn_note"
            >
              <span>ارسل للكل</span>
            </button>
          </div>
          <!--   <div class="col-lg-8 col-12">

                  </div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-12">
      <div v-if="!IsGust">
        <div
          class="active_meeting_title d-flex "
          v-if="!user_roles.includes('viewer')"
        >
          <button
            type="button"
            class="btn_draft btn_actions"
            v-clipboard:copy="`${base_URL}/#/mt/${meeting.slug}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <i class="fas fa-link"></i>
            <span>نسخ رابط الاجتماع</span>
          </button>
          <button @click="popupAddUser = true" class="btn_Green btn_main ">
            <i class="fas fa-plus"></i>
            <span> إضافة عضو جديد </span>
          </button>
        </div>
      </div>
      <div class="meeting_tasks">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info"
                >مواضيع الاجتماع</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="topics_list">
                  <!-- completed -->
                  <div
                    v-for="(item, index) in meeting_topics"
                    :key="item.id"
                    class="step"
                    :class="item.checked ? 'completed' : ''"
                  >
                    <div class="v-stepper">
                      <div class="circle" @click="markTopic(item.id)">
                        <i class="fa fa-check"></i>
                      </div>
                      <div class="line"></div>
                    </div>
                    <div
                      v-if="Edit_Topick && edited_topick == index"
                      class="w-100 input-search position-relative"
                    >
                      <input
                        ref="editedTopic"
                        v-model="EditedTopic"
                        class="form-control"
                        type="search"
                        name="meeting-link"
                        id="editedTopic"
                        placeholder="موضوع الاجتماع"
                      />
                      <button
                        type="button"
                        @click="EditTopickinPlace(index)"
                        class="add_input_icon"
                      >
                        <img src="/media/svg/edit_w.svg" />
                      </button>
                    </div>
                    <div v-else class="content">
                      {{ item.topic }}
                      <div class="list_actions">
                        <!--                        <button-->
                        <!--                          type="button"-->
                        <!--                          @click="EditTopick(item, index)"-->
                        <!--                          class="p-0 mr-2 text-center"-->
                        <!--                        >-->
                        <!--                          <img-->
                        <!--                            style="width: 12px"-->
                        <!--                            src="/media/svg/edit_metings.svg"-->
                        <!--                          />-->
                        <!--                        </button>-->
                        <!--                        <button-->
                        <!--                          type="button"-->
                        <!--                          @click="delete_meeting_topic(item.id)"-->
                        <!--                          class="p-0 text-center"-->
                        <!--                        >-->
                        <!--                          <img-->
                        <!--                            style="width: 12px"-->
                        <!--                            src="/media/svg/delete.svg"-->
                        <!--                          />-->
                        <!--                        </button>-->
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info"
                >المهام المطلوبة</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div
                  v-if="!IsGust"
                  class="d-block mb-2 new_task"
                  style="min-height: 40px"
                >
                  <div class="col-md-12 to_drop_in mb-2">
                    <draggable
                      class="list-group p-2"
                      :list="TaskList"
                      :group="{ name: 'people', pull: false, put: true }"
                    >
                      <template v-if="TaskList.length">
                        <div
                          v-for="element in TaskList"
                          :key="element.name"
                          class="listItem"
                        >
                          <div class="user_data">
                            <img
                              :src="element.img ? element.img : element.avatar"
                            />
                            <span class="user_name">{{ element.name }}</span>
                          </div>
                        </div>
                      </template>
                      <div
                        style="height: 15px; font-size: 8px"
                        class="selectPlaceholder"
                        v-else
                      >
                        إضافة شخص
                      </div>
                    </draggable>
                  </div>
                  <div
                    class="col-md-12 input-search tasks_input position-relative w-100 p-0"
                  >
                    <input
                      v-model="taskText"
                      type="text"
                      name="meeting-link"
                      placeholder="المهمة المطلوب تنفيذها"
                    />

                    <button v-if="loadingaddTask" class="add_input_icon">
                      <div class="loader_main"></div>
                    </button>
                    <button v-else @click="btnAddTask" class="add_input_icon">
                      <img src="/media/svg/plus.svg" />
                    </button>
                  </div>
                </div>
                <div class="tasks" v-if="MeetingTasks.length">
                  <ul>
                    <li v-for="(task, index) in MeetingTasks" :key="task.id">
                      <div
                        v-if="Edit_Tasks && edited_task == index"
                        class="d-block bg_gray p_list w-100"
                      >
                        <b-dropdown
                          position="is-bottom-left"
                          class="dropdownList"
                          aria-role="list"
                        >
                          <template #trigger>
                            <a
                              class="navbar-item p-0"
                              role="button"
                              v-if="task.users && task.users.length"
                            >
                              <div
                                v-if="task.users.length"
                                class="user_data m-0"
                              >
                                <img :src="task.users[0].avatar" />
                                <span class="user_name ml-2">
                                  {{ task.users[0].name }}
                                </span>
                                <b-icon icon="menu-down mr-auto"></b-icon>
                              </div>
                              <div v-else class="user_data m-0">
                                <img :src="task.groups[0].image" />
                                <span class="user_name ml-2">
                                  {{ task.groups[0].name }}
                                  <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text=""
                                    ></truncate> -->
                                </span>
                                <b-icon icon="menu-down mr-auto"></b-icon>
                              </div>
                            </a>
                          </template>
                          <b-dropdown-item
                            paddingless
                            aria-role="listitem"
                            v-for="item in task.users"
                            :key="item.id"
                          >
                            <div class="user_data mb-2">
                              <img :src="item.avatar" />
                              <span class="user_name">{{ item.name }}</span>
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item
                            paddingless
                            aria-role="listitem"
                            v-for="item in task.groups"
                            :key="item.id"
                          >
                            <div class="user_data mb-2">
                              <img :src="item.image" />
                              <span class="user_name">{{ item.name }}</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <div class="d-flex position_relative">
                          <h6>
                            {{ task.required }}
                          </h6>
                          <div class="list_actions">
                            <button
                              type="button"
                              @click="EditTasks(task, index)"
                              class="p-0 mr-2 text-center"
                            >
                              <img
                                style="width: 12px"
                                src="/media/svg/edit_metings.svg"
                              />
                            </button>
                            <button
                              v-if="loadingDeleteTask && index == deletedTask"
                              class="p-0 text-center"
                            >
                              <div class="loader_delete"></div>
                            </button>

                            <button
                              v-else
                              @click="delete_meeting_task(task.id, index)"
                              class="p-0 text-center"
                            >
                              <img
                                style="width: 12px"
                                src="/media/svg/delete.svg"
                              />
                            </button>
                          </div>
                        </div>
                        <div class="mt-5 position_relative">
                          <input
                            ref="editedTopic"
                            v-model="EditedTask.required"
                            class="form-control"
                            type="search"
                            name="meeting-link"
                            id="editedTopic"
                          />
                          <div class="text-right mt-1">
                            <!--  -->
                            <button
                              v-if="loadingTaskEdited"
                              type="button"
                              class="btn_Green btn_main_edit"
                            >
                              <div class="loader_edit"></div>
                            </button>
                            <button
                              v-else
                              type="button"
                              @click="update_meeting_tasks(index)"
                              class="btn_Green btn_main_edit"
                            >
                              تعديل
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-else class="d-block w-100 position_relative">
                        <b-dropdown
                          position="is-bottom-left"
                          class="dropdownList"
                          aria-role="list"
                        >
                          <template #trigger>
                            <a
                              class="navbar-item p-0"
                              role="button"
                              v-if="task.users && task.users.length"
                            >
                              <div
                                v-if="task.users.length"
                                class="user_data bg_gray m-0"
                              >
                                <img :src="task.users[0].avatar" />
                                <span class="user_name ml-2">
                                  {{ task.users[0].name }}
                                  <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text="task.users[0].name"
                                    ></truncate -->
                                </span>
                                <b-icon icon="menu-down mr-auto"></b-icon>
                              </div>
                              <div v-else class="user_data bg_gray m-0">
                                <img :src="task.groups[0].image" />
                                <span class="user_name ml-2">
                                  {{ task.groups[0].name }}
                                  <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text="task.groups[0].name"
                                    ></truncate -->
                                </span>

                                <b-icon icon="menu-down mr-auto"></b-icon>
                              </div>
                            </a>
                          </template>
                          <b-dropdown-item
                            paddingless
                            aria-role="listitem"
                            v-for="item in task.users"
                            :key="item.id"
                          >
                            <div class="user_data mb-2">
                              <img :src="item.avatar" />
                              <span class="user_name">{{ item.name }}</span>
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item
                            paddingless
                            aria-role="listitem"
                            v-for="item in task.groups"
                            :key="item.id"
                          >
                            <div class="user_data mb-2">
                              <img :src="item.image" />
                              <span class="user_name">{{ item.name }}</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <div
                          class="d-block topic_list p_list position_relative"
                        >
                          <h6>
                            {{ task.required }}
                          </h6>
                          <div class="list_actions">
                            <button
                              type="button"
                              @click="EditTasks(task, index)"
                              class="p-0 mr-2 text-center"
                            >
                              <img
                                style="width: 12px"
                                src="/media/svg/edit_metings.svg"
                              />
                            </button>
                            <button
                              v-if="loadingDeleteTask && index == deletedTask"
                              class="p-0 text-center"
                            >
                              <div class="loader_delete"></div>
                            </button>

                            <button
                              v-else
                              @click="delete_meeting_task(task.id, index)"
                              class="p-0 text-center"
                            >
                              <img
                                style="width: 12px"
                                src="/media/svg/delete.svg"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist" v-if="!IsGust">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info">
                المرفقات</b-button
              >
              <i
                class="fa fa-plus add_new_attachment"
                @click="showAddAttachmentBox = true"
              ></i>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <ul class="list-group list-group-flush attachments_list">
                  <li
                    class="list-group-item"
                    v-for="(item, index) in meeting_attachments"
                    :key="index"
                  >
                    <div class="filename">{{ item.name }}</div>
                    <div class="download">
                      <a :href="item.url" download>
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <div class="centerx">
      <vs-popup
        class="holamundo"
        :title="'إضافة عضو الى الإجتماع'"
        :active.sync="popupAddUser"
        :button-close-hidden="true"
      >
        <div class="search-people">
          <span class="title_addUser">البحث باسم الشخص</span>
          <v-select
            @input="EditMeetingMembers"
            v-model="user_selected"
            :options="AllUsers"
            placeholder="البحث باسم الشخص"
            label="name"
            :reduce="(user) => user.id"
            @search="onSearch"
          />
          <p class="gray-clr">
            إذا لم تجد الشخص الذي تبحث عنه يمكنك إضافته
            <a
              type="button"
              @click="
                popupAddUser = false;
                popupCreateUser = true;
              "
              class="here"
              >من هنا</a
            >
          </p>
        </div>
        <div class="popoup_footer justify-content-end d-flex">
          <button class="btn_Gray btn_main" @click="popupAddUser = false">
            <span>خروج</span>
          </button>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        :title="'إضافة مرفقات'"
        :active.sync="showAddAttachmentBox"
        :button-close-hidden="true"
      >
        <div class="form-group">
          <label>اسم الملف</label>
          <input type="text" class="form-control" v-model="attachment.name" />
        </div>
        <input
          type="file"
          class="form-control"
          @change="attachmentFileSelected"
        />
        <br />
        <div class="popoup_footer justify-content-end d-flex">
          <button class="btn_Green btn_main" @click="uploadAttachment">
            <span>تحميل</span>
          </button>
          <button
            class="btn_Gray btn_main"
            @click="showAddAttachmentBox = false"
          >
            <span>خروج</span>
          </button>
        </div>
      </vs-popup>
    </div>
    <AddMembers
      v-if="popupCreateUser"
      :popupAddUser="popupCreateUser"
      :closePopup="closePopup"
      :getAllUser="getAllUser"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
// import FlipCountdown from "vue2-flip-countdown";
import VueCountdown from "@chenfengyuan/vue-countdown";
import meetings from "@/http/requests/meetings";
import users from "@/http/requests/users";
import vSelect from "vue-select";
import AddMembers from "../../../components/users/AddMembers";
// import truncate from "vue-truncate-collapsed";

// import ZoomFrame from "../../../components/ZoomFrame";
export default {
  name: "ActiveMeeting",

  data() {
    return {
      user_selected: "",
      popupCreateUser: false,
      popupAddUser: false,
      Remainin_time: 0,
      Remainin_time_to_start: 0,
      meeting_id: null,
      TaskList: [],
      taskText: [],
      meeting: {},
      meeting_users: [],
      meeting_topics: [],
      meeting_attachments: [],
      taskUsers: [],
      taskGroups: [],
      myHTML: null,

      ramin_day: null,
      ramin_h: null,
      ramin_m: null,
      ramin_s: null,
      base_URL: "",
      Metting_stutes: null,
      Edit_Tasks: false,
      edited_task: "",
      EditedTask: {},
      MeetingTasks: [],
      myHTML_id: null,

      Edit_Topick: false,
      edited_topick: undefined,
      EditedTopic: "",
      allTopicsSelected: [],
      AllUsers: [],
      allMembersSelected: [],
      allGroupsSelected: [],
      user_date: {},
      taskNew: {},
      // loading
      loadingaddTask: false,
      loadingDeleteTask: false,
      deletedTask: null,
      loadingTaskEdited: false,
      showAddAttachmentBox: false,
      attachment: {
        name: "",
        file: null,
      },
      IsGust: false,
      user_roles:[],
    };
  },

  // draggable
  components: {
    "v-select": vSelect,
    VueCountdown,
    draggable,
    AddMembers,
  },
  beforeRouteLeave(to, from, next) {
    this.$pusher.unsubscribe("presence-meeting." + this.$route.params.id);
    next();
  },
  methods: {
    onSearch(search, loading) {
      loading(true);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (search) {
          users.getusers(1, search).then((res) => {
            this.AllUsers = res.data.data;
            loading(false);
          });
        } else {
          loading(false);
          this.getAllUser();
        }
      }, 500);
    },
    getAllUser() {
      this.AllUsers = [];
      users
        .getusers(this.page, this.search_word)
        .then((res) => {
          this.AllUsers = res.data.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    closePopup() {
      this.popupCreateUser = false;
    },
    btnAddTask() {
      this.loadingaddTask = true;
      this.taskUsers = [];
      this.taskGroups = [];
      for (let i = 0; i < this.TaskList.length; i++) {
        if (this.TaskList[i].email) {
          this.taskUsers.push(this.TaskList[i]);
        } else {
          this.taskGroups.push(this.TaskList[i]);
        }
      }
      if (this.taskUsers.length || this.taskGroups.length) {
        this.taskNew = {
          required: this.taskText,
          users: this.taskUsers.map((item) => item.id),
          groups: this.taskGroups.map((item) => item.id),
          meeting_id: this.meeting.id,
        };
      } else {
        this.$vs.notify({
          text: "برجاء اختيار عضو او مجموعة",
          color: "danger",
        });
      }
      this.TaskList = [];
      this.taskText = "";
      meetings
        .single_task_store(this.taskNew)
        .then(() => {
          this.loadingaddTask = false;
          this.Edit_Topick = false;
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم إضافة المهمة للإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    onCopy(text) {
      this.$vs.notify({
        text: "تم نسخ الرابط بنجاح",
        color: "success",
      });
    },
    onError() {
      alert("Failed to copy texts");
    },
    onLoad(frame) {
      this.myIframe = frame.contentWindow;
    },
    EditTasks(task, index) {
      this.Edit_Tasks = true;
      this.edited_task = index;
      this.EditedTask = task;
    },
    // EditaskInPlace(index) {
    //   this.EditedTask.required = this.EditedTask.required;
    //   this.EditedTask.users = this.EditedTask.users.map((item) => item.id);
    //   this.EditedTask.groups = this.EditedTask.groups.map((item) => item.id);

    //   this.update_meeting_tasks();
    // },
    EditTopick(text, index) {
      this.Edit_Topick = true;
      this.edited_topick = index;
      this.EditedTopic = text.topic;
    },
    EditTopickinPlace(index) {
      this.allTopicsSelected[index] = this.EditedTopic;
      this.update_meeting_topics();
    },
    update_meeting_topics() {
      this.$vs.loading();
      const form_section3 = {
        id: this.meeting.id,
        topics: this.allTopicsSelected,
      };
      meetings
        .update_meeting_topics(form_section3)
        .then(() => {
          this.$vs.loading.close();
          this.getMeetingTasks();
          this.Edit_Topick = false;
          this.$vs.notify({
            text: "تم تعديل  مواضيع الإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },

    update_meeting_tasks() {
      this.loadingTaskEdited = true;
      const form_section3 = {
        id: this.EditedTask.id,
        required: this.EditedTask.required,
        users: this.EditedTask.users.map((item) => item.id),
        groups: this.EditedTask.groups.map((item) => item.id),
      };
      meetings
        .update_single_task(form_section3)
        .then(() => {
          this.loadingTaskEdited = false;
          this.Edit_Tasks = false;
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم تعديل  مهام الإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    EditMeetingMembers(new_user) {
      this.$vs.loading();
      this.allMembersSelected.push(new_user);
      const form_section2 = {
        id: this.meeting.id,
        users: this.allMembersSelected,
        groups: this.allGroupsSelected,
      };

      meetings
        .EditMeetingMembers(form_section2)
        .then(() => {
          this.getMeetingUsers();
          this.user_selected = "";
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل  اعضاء الإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    getMeeting() {
      this.$vs.loading();
      this.meeting = {};
      meetings.getSingleMeeting(this.$route.params.id).then((res) => {
        var end = res.data.data.end_time;
        var start = res.data.data.start_time;
        this.Remainin_time = new Date(end) - new Date();
        this.Remainin_time_to_start = new Date(start) - new Date();

        if (new Date(end) < new Date()) {
          this.Metting_stutes = "complete";
        }
        if (res.data.data.note) {
          this.myHTML = res.data.data.note.note;
          this.myHTML_id = res.data.data.note.note.id;
        }

        this.allTopicsSelected = res.data.data.topics.map((item) => item.topic);
        (this.allMembersSelected = res.data.data.users.map((item) => item.id)),
          (this.allGroupsSelected = res.data.data.groups.map(
            (item) => item.id
          )),
          (this.meeting_id = res.data.data.zoom_id);
        this.meeting = res.data.data;
        this.user_join_meeting();

        if (
          (this.user_date.email == res.data.data.coordinator.email || this.user_roles.includes("admin")) && res.data.data.status == "inprogress"
        ) {
          meetings.admin_start_meeting(res.data.data.id).then(() => {
            this.meeting.admin_start_meeting = 1;
          });
        }
        if (res.data.data.status == "inprogress") {
          const join_data = {
            meeting_id: res.data.data.id,
            user_id: this.user_date.id,
            is_admin:
              this.user_date.email == res.data.data.coordinator.email ||
              this.user_roles.includes("admin")
                ? 1
                : 0,
          };
        }
        this.getMeetingTopics();
        this.getMeetingUsers();
        this.getMeetingAttachments();
        this.$vs.loading.close();
      });
    },
    getMeetingTasks() {
      (this.MeetingTasks = []),
        meetings.list_meeting_task(this.$route.params.id).then((res) => {
          this.MeetingTasks = res.data.data;
        });
    },
    CreateMeetingNote(send) {
      if (this.myHTML) {
        this.$vs.loading();
        const data = {
          note: this.myHTML,
          meeting_id: this.meeting.id,
          users:
            send == "send" ? this.meeting.users.map((item) => item.id) : [],
        };

        meetings.CreateMeetingNote(data).then((res) => {
          if (send == "send") {
            this.$vs.notify({
              text:
                send == "send"
                  ? "تم إرسال الملخص بنجاح"
                  : "تم حفظ ملخص الإجتماع بنجاح",
              color: "success",
            });
          } else {
            this.$router.push(`/meeting_finshed/${this.$route.params.id}`);
          }

          this.$vs.loading.close();
        });
      } else {
        this.$router.push(`/meeting_finshed/${this.$route.params.id}`);
      }
    },
    delete_meeting_topic(id) {
      this.$vs.loading();
      meetings
        .delete_meeting_topic(id)
        .then((res) => {
          this.getMeeting();
          this.$vs.notify({
            text: "تم حذف الموضوع بنجاح",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    delete_meeting_task(id, index) {
      this.deletedTask = index;
      this.loadingDeleteTask = true;
      meetings
        .delete_meeting_task(id)
        .then((res) => {
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم حذف المهمه بنجاح",
            color: "success",
          });
          this.loadingDeleteTask = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },

    user_left_meeting() {
      const left_data = {
        meeting_id: this.meeting.id,
        // user_id: this.user_date.id,
        is_admin:
          this.user_date.email == this.meeting.email ||
          this.user_roles.includes("admin")
            ? 1
            : 0,
      };
      meetings.user_left_meeting(left_data).then(() => {
        window.location.href = "/";
      });
    },
    user_join_meeting() {
    
      const left_data = {
        meeting_id: this.meeting.id,
        // user_id: this.user_date.id,
        is_admin:
          this.user_date.email == this.meeting.email ||
          this.user_roles.includes("admin")
            ? 1
            : 0,
      };
      meetings.user_join_meeting(left_data).then(() => {});
    },
    getMeetingTopics() {
      meetings.getMeetingTopics(this.meeting.id).then((res) => {
        this.meeting_topics = res.data;
      });
    },
    getMeetingUsers() {
      meetings.getMeetingUsers(this.meeting.id).then((res) => {
        this.meeting_users = res.data;
      });
    },
    markTopic(topic_id) {
      meetings.markMeetingTopic(this.meeting.id, topic_id).then();
    },
    getMeetingAttachments() {
      meetings.getMeetingAttachments(this.meeting.id).then((res) => {
        this.meeting_attachments = res.data;
      });
    },
    attachmentFileSelected(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.attachment.file = files[0];
    },
    uploadAttachment() {
      let formData = new FormData();
      formData.append("name", this.attachment.name);
      formData.append("file", this.attachment.file);
      meetings.uploadAttachment(this.meeting.id, formData);
      this.attachment = { name: "", file: null };
      this.showAddAttachmentBox = false;

      this.getMeetingAttachments();
    },
    listenForRealtimeEvents() {
      const meetingsChannel = this.$pusher.subscribe(
        "presence-meeting." + this.$route.params.id
      );

      const self = this;
      meetingsChannel.bind("tasks.changed", function($e) {
        self.getMeetingTasks();
      });

      meetingsChannel.bind("topics.changed", function($e) {
        self.getMeetingTopics();
      });

      meetingsChannel.bind("users.changed", function($e) {
        self.getMeetingUsers();
      });

      meetingsChannel.bind("attachments.changed", function($e) {
        self.getMeetingAttachments();
      });

      meetingsChannel.bind("pusher:member_added", function(member) {
        self.getMeetingUsers();
      });

      meetingsChannel.bind("pusher:member_removed", function(member) {
        self.getMeetingUsers();
      });
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   next();
  //    this.user_date = localStorage.getItem("User_data")
  //     ? JSON.parse(localStorage.getItem("User_data"))
  //     : {};
  //   if (window.localStorage.getItem("gust_token")) {
  //     this.IsGust = true;
  //     this.user_roles = ['gust'];
  //   }
  //   else{
  //      this.user_roles =this.user_date.roles ;
  //   }

  //   this.getMeeting();
  //   this.getAllUser();
  //   this.base_URL = window.location.origin;
  // },
  created() {
    this.user_date = localStorage.getItem("User_data")
      ? JSON.parse(localStorage.getItem("User_data"))
      : {};

    if (window.localStorage.getItem("gust_token")) {
      this.IsGust = true;
      this.user_roles = ["gust"];
    } else {
      this.user_roles = this.user_date.roles;
    }
    this.base_URL = window.location.origin;

    this.getMeeting();
    this.getMeetingTasks();
    this.getAllUser();
    this.listenForRealtimeEvents();
  },
};
</script>
<style scoped lang="scss">
$thirdColor: #17a1bd;
//  #1bb04e: #1bb04e;
.search-people {
  min-height: 300px !important;
  .gray-clr {
    font-size: 15px;
    margin-top: 14px;
    a {
      text-decoration: underline;
      color: $thirdColor;
    }
  }
}
.btn_Gray {
  padding: 5px 20px;
  margin-right: 0;
}
.btn_note {
  display: block;
  margin: auto;
}
.btn_danger {
  min-width: 70px;
  margin-right: 15px;
  height: 40px;
  padding: 0 13px;
  @media (max-width: 992px) {
    margin-right: 0;
    margin-top: 15px;
  }
}

.new_task {
  border: 1px solid #d1d1d1;
  padding: 5px;
}
.active_meeting .step .line {
}

.listUsers {
  div {
    .listItem {
      .userStatus {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;

        div {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          &.online {
            background: #1bb04e;
          }
          &.away {
            background: #ff8d00;
          }
          &.offline {
            background: gray;
          }
        }
      }
    }
  }
}

.attachments_list {
  margin: 0;
  li.list-group-item {
    display: flex;
    .filename {
      word-break: break-all;
    }
    .download {
      align-self: center;
      margin-right: auto;
      a {
        i {
          margin-right: 5px;
          color: #17a1bd;
        }
      }
    }
  }
}
.add_new_attachment {
  position: absolute;
  left: 13px;
  top: 18px;
}
/*  */
</style>
